<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col">
        <div class="h-100">
          <div class="row mb-3 pb-1">
            <!--end col-->
          </div>
          <!--end row-->

          <div class="row">
            <div class="col-xl-3 col-md-6">
              <!-- card -->
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                        Année Scolaire
                      </p>
                    </div>
                  </div>
                  <div class="d-flex align-items-end justify-content-between mt-4">
                    
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-success rounded fs-3">
                        <i class="ri-calendar-2-line text-success"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->

            <div class="col-xl-3 col-md-6">
              <!-- card -->
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                        Nombre d'étudiants
                      </p>
                    </div>
                    <div class="flex-shrink-0">
                      <!-- <h5 class="text-danger fs-14 mb-0">
                        <i
                          class="ri-arrow-right-down-line fs-13 align-middle"
                        ></i>
                        -3.57 %
                      </h5> -->
                    </div>
                  </div>
                  <div class="d-flex align-items-end justify-content-between mt-4">
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        <count-to :startVal='0' :endVal='2689' :duration='5000'></count-to>
                      </h4>
                      <!-- <a href="" class="text-decoration-underline"
                        >View all orders</a
                      > -->
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-info rounded fs-3">
                        <i class="bx bx-shopping-bag text-info"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->

            <div class="col-xl-3 col-md-6">
              <!-- card -->
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                        Nombre de professeurs
                      </p>
                    </div>
                    <div class="flex-shrink-0">
                    </div>
                  </div>
                  <div class="d-flex align-items-end justify-content-between mt-4">
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        <count-to :startVal='0' :endVal='18' :duration='5000'></count-to>
                      </h4>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-warning rounded fs-3">
                        <i class="bx bx-user-circle text-warning"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->

            <div class="col-xl-3 col-md-6">
              <!-- card -->
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                        Ecolage
                      </p>
                    </div>
                  </div>
                  <div class="d-flex align-items-end justify-content-between mt-4">
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        <count-to :startVal='0' :endVal='16500000' :duration='5000'></count-to>
                      </h4>
                      <!-- <a href="" class="text-decoration-underline"
                        >Withdraw money</a
                      > -->
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-primary rounded fs-3">
                        <i class="bx bx-wallet text-primary"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->
          </div>
          <!-- end row-->
        </div>
        <!-- end .h-100-->
      </div>
      <!-- end col -->
      <!-- end col -->
    </div>
  </Layout>
</template>

<script>
import SwiperCore from "swiper";
SwiperCore.use([]);

import "flatpickr/dist/flatpickr.css";
import { CountTo } from "vue3-count-to";
import { mapGetters } from 'vuex';

import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";


export default {
  components: {
    CountTo,
    Layout,
    PageHeader,

  },
  data() {
    return {

      data: {
      },
      title: "Accueil",
      items: [
        {
          text: "Accueil",
          href: "/",
        },
        {
          text: "Accueil",
          active: true,
        },
      ],
      date: null,
      config: {
        mode: "range",
      },
      series: [44, 55, 41, 17, 15],
      chartOptions: {
        labels: ["Direct", "Social", "Email", "Other", "Referrals"],
        chart: {
          height: 333,
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        stroke: {
          show: false,
        },
        dataLabels: {
          dropShadow: {
            enabled: false,
          },
        },
        colors: ["#405189", "#0AB39C", "#F7B84B", "#F06548", "#299CDB"],
      },
    };
  },
  computed: {
    ...mapGetters(['helloWord', 'selectedYear', 'getloggedUser']),
    get() {
      return this.getloggedUser;
    }

  },
  methods: {

    rightcolumn() {
      if (document.querySelector('.layout-rightside-col').classList.contains('d-none')) {
        document.querySelector('.layout-rightside-col').classList.remove('d-none')
      } else {
        document.querySelector('.layout-rightside-col').classList.add('d-none')
      }
    },

  },
  watch: {
    
  },
  mounted() {
  }

};
</script>

